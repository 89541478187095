<template>
    <div class="magic-link-container">
      <!-- Mensaje de carga o error -->
      <div v-if="loading">Cargando...</div>
      <div v-if="error">{{ errorMessage }}</div>
    </div>
  </template>
  
  <script>
  import { useToast } from 'vue-toastification';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import { ref, onMounted } from 'vue';
  
  export default {
    props: {
      magictoken: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const toast = useToast();
      const store = useStore();
      const router = useRouter();
      const loading = ref(true);
      const error = ref(false);
      const errorMessage = ref('');
  
      const fetchUserData = () => {
        axios.post(`/api/magic-link-login/${props.magictoken}`)
          .then(response => {
            if (response.data.success) {
              store.dispatch('login', response.data.user)
                .then(() => {
                  router.push('/');
                })
                .catch(err => {
                  console.error('Error after login action:', err);
                  toast.error('Error después de iniciar sesión');
                });
            } else {
              throw new Error(response.data.error || 'Error desconocido');
            }
          })
          .catch(err => {
            error.value = true;
            errorMessage.value = err.message;
            toast.error(errorMessage.value);
            router.push('/login');
          })
          .finally(() => {
            loading.value = false;
          });
      };
  
      onMounted(fetchUserData);
  
      return {
        loading,
        error,
        errorMessage,
      };
    }
  };
  </script>
  
  <style>
  .magic-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  </style>
  