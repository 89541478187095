import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import { Chart, registerables } from 'chart.js';
import VueChart3 from 'vue-chart-3';
import './assets/styles/tailwind.css'


Chart.register(...registerables);
const app = createApp(App)

// Usar las librerías y configuraciones
app.use(store)
app.use(router)




app.use(Toast, {
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
  maxToasts: 10
});

app.use(VueChart3);

app.mount('#app')
