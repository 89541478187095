<template>
    <div class="container mx-auto px-4 py-6">
      <h1 class="text-3xl font-semibold text-center text-gray-800 mb-8">Aprobación de usuarios</h1>
      <div v-for="(groupData, groupId) in event" :key="groupId" class="mb-6 p-4 bg-white shadow-md rounded-lg contenedor-grupo">
        <h2 class="text-2xl font-semibold text-gray-700 mb-4">Grupo: {{ groupData.datadelgrupo.name }}</h2>
        
        <!-- Staff -->
        <div v-if="hasCategory(groupData.staffs.usuarios, 'Staff')">
            <hr>
            <br>
          <h3 class="text-xl font-semibold text-gray-600 mb-3">Staffs</h3>
          <div v-for="user in filteredUsers(groupData.staffs.usuarios, 'Staff')" :key="user.staff_id">
            <hr>
            <UserDetails :user="user" @approve="approveUser(user.staff_id)" />
          </div>
        </div>
        <!-- Asistentes -->
        <div v-if="hasCategory(groupData.staffs.usuarios, 'Asistente')">
            <hr>
            <br>
          <h3 class="text-xl font-semibold text-gray-600 mb-3">Asistentes</h3>
          <div v-for="user in filteredUsers(groupData.staffs.usuarios, 'Asistente')" :key="user.staff_id">
            <hr>
            <UserDetails :user="user" @approve="approveUser(user.staff_id)" />
          </div>
        </div>
  
        <!-- Expositores -->
        <div v-if="hasCategory(groupData.staffs.usuarios, 'Conferencista')">
            <hr>
            <br>
          <h3 class="text-xl font-semibold text-gray-600 mb-3">Expositores</h3>
          <div v-for="user in filteredUsers(groupData.staffs.usuarios, 'Conferencista')" :key="user.staff_id">
            <hr>
            <UserDetails :user="user" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import UserDetails from './UserDetails.vue'; // Asegúrate de crear y importar este componente.
  import { useToast } from "vue-toastification";
  export default {
    props: {
      eventoToken: {
        type: String,
        required: true
      }
    },
    components: {
        UserDetails
    },
    data() {
      return {
        event: {}
      };
    },
    setup() {      
      const toast = useToast();
      return { toast }
    },
    mounted() {
      this.fetchMembers();
    },
    methods: {
      fetchMembers() {
        axios.post('/api/list/members-staff', { token_evento: this.eventoToken })
          .then(response => {
            this.event = response.data.evento;
          })
          .catch(error => {
            console.error('Error fetching members:', error);
          });
      },
   
    updateStaffMember(staffId, updates) {
    Object.keys(this.event).forEach(groupId => {
        let index = this.event[groupId].staffs.usuarios.findIndex(user => user.staff_id === staffId);
        if (index !== -1) {
        // Directamente asignar un nuevo objeto al índice para garantizar reactividad
        this.event[groupId].staffs.usuarios[index] = {
            ...this.event[groupId].staffs.usuarios[index],
            ...updates
        };
        }
    });
    },


      hasCategory(users, category) {
        return users.some(user => user.escarapela === category);
      },
      filteredUsers(users, category) {
        return users.filter(user => user.escarapela === category );
        // return users.filter(user => user.escarapela === category && user.escarapela !== 'Staff');
      }
    }
  }
  </script>
  
<style lang="sass" scoped>
  .contenedor-grupo 
    width: 100%
    max-width: 1200px
    
</style>