<template>
    <div v-if="visible" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div class="bg-white p-4 rounded-lg shadow-lg max-w-sm mx-auto">
        <h2 class="text-lg font-bold mb-2">Confirmación</h2>
        <p class="mb-4">{{ message }}</p>
        <p class="text-blue-600 break-all">{{ link }}</p>
        <div class="flex justify-between items-center mt-4">
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="copyLink">Copiar link</button>
          <button class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" @click="$emit('close')">Cerrar</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useToast } from "vue-toastification";
  export default {
    setup() {      
      const toast = useToast();
      return { toast }
    },
    props: {
      visible: Boolean,
      link: String,
      message: {
        type: String,
        default: 'Este link ha sido enviado por correo al usuario.'
      }
    },
    methods: {
      copyLink() {
        navigator.clipboard.writeText(this.link)
          .then(() => {
            this.toast.success('Link copiado al portapapeles');
          })
          .catch(err => {
            console.error('No se pudo copiar el texto: ', err);
          });
      }
    }
  }
  </script>
  
  <style scoped>
  /* Estilos opcionales para mejorar la apariencia del modal */
  .fixed {
    z-index: 1000;
  }
  .bg-opacity-50 {
    backdrop-filter: blur(4px);
  }
  </style>
  