<template>
    <div>
      <!-- Botón para activar la generación de magic links -->
      <button @click="generateMagicLinks" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded">
        Enviar Magic Links
      </button>
  
      <!-- Overlay que aparece mientras se procesa la solicitud -->
      <div v-if="processing" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
        <p class="text-white text-xl">Procesando solicitud...</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useToast } from "vue-toastification";
  
  export default {
    props: ['eventoToken'],
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        processing: false,
      };
    },
    methods: {
      generateMagicLinks() {
        this.processing = true;
        axios.post('/api/generate-magic-link/event', { eventoToken: this.eventoToken })
          .then(response => {
            this.handleResponse(response.data);
            this.toast.success('Solicitud procesada correctamente.');
          })
          .catch(error => {
            console.error('Error al generar magic links:', error);
            this.toast.error('Error al procesar la solicitud.');
          })
          .finally(() => {
            this.processing = false;
          });
      },
      handleResponse(data) {
        const content = data.links.map(link => `(enviado: ${link.mail}) ${link.correo} \nlink: ${link.magic_link}`).join('\n\n');
        this.downloadTextFile(content, 'magiclinks.txt');
      },
      downloadTextFile(text, filename) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
  
        element.style.display = 'none';
        document.body.appendChild(element);
  
        element.click();
  
        document.body.removeChild(element);
      }
    }
  }
  </script>
  
  <style scoped>
  .fixed {
    z-index: 50;
  }
  </style>
  