<template>
    <div class="container mx-auto px-4 py-6">
      <!-- Botón para abrir el modal -->
        <div v-if="canView('superadministrador', 'administradorevento')" class="boton-svg">
        <button @click="showModalButtons = true" class="modal-trigger-button">
            <!-- Ícono SVG en forma de X -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>
        </button>
        </div>

        <!-- Modal que contiene los botones -->
        <div v-if="showModalButtons" class="modal">
        <div class="modal-content">
            <div v-if="canView('superadministrador')" class="botones">
                <AgregarSuperAdministrador @userCreated="fetchGroups" :eventoToken="eventoToken" />
                <a target="_blank" style="margin-bottom: 10px; display: block;" class="boton-front bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded w-full" :href="`/approve-users/${eventoToken}`">Aprobación de usuarios</a>
            </div>
            <div v-if="canView('superadministrador', 'administradorevento')" class="botones">
                <a target="_blank" style="margin-bottom: 10px; display: block;" class="boton-front bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded w-full" :href="`/check-status-event/${eventoToken}`">Resumen evento</a>
                <CreateGroupModal v-if="!fechaCorte" @groupCreated="fetchGroups" :eventoToken="eventoToken" />
                <CargaGrupos v-if="!fechaCorte" @groupCreated="fetchGroups" :eventoToken="eventoToken" />
                <AgregarAdministradorEvento v-if="!fechaCorte" @userCreated="fetchGroups" :eventoToken="eventoToken" />
                <EnvioMagicLinksEvento  :eventoToken="eventoToken" />
            </div>
            <button @click="showModalButtons = false" class="mt-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cerrar</button>
        </div>
        </div>
      <h2 class="text-xl font-semibold text-gray-800 mb-4">Lista de Usuarios por Grupo</h2>
      <DownloadButton v-if="!fechaCorte" />
      <div v-if="loading">
        Cargando usuarios...
      </div>
      <div v-else style="width: 100%">
        <div v-for="group in groups" :key="group.group_id" class="mb-4 p-4 bg-white shadow-md rounded-lg">
          <div class="flex justify-between items-center mb-3">
            <h3 class="text-lg titulo-grupo font-bold">
              <EditGroupModal 
                v-if="canView('superadministrador','administradorevento') && !fechaCorte"
                :group="group"
                v-model:staff="group.staff"
                v-model:asistentes="group.asistentes"
                v-model:conferencistas="group.conferencistas"
                @groupUpdated="handleGroupUpdated"
                @groupDeleted="handleGroupDeleted"
              />
              <EnvioMagicLinksGrupo  v-if="canView('superadministrador','administradorevento','administrador') && !fechaCorte" :grupoToken="group.group_id"/>
              {{ group.group_name }}
            </h3>
            <div>
              <button v-if="canView('superadministrador','administradorevento','administrador') && !fechaCorte" @click="addUser(group.group_id)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded">Agregar Moderador</button>
            </div>
          </div>
          <div class="mb-10" v-if="group.users.length > 0 && canView('superadministrador','administrador','administradorevento')">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-200">
                <tr>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase">Nombre</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase">Email</th>
                  <th v-if="!fechaCorte" class="px-4 py-2 text-left text-xs font-medium text-gray-600 uppercase">Acciones</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-300">
                <tr v-for="user in group.users" :key="user.id">
                  <td class="px-4 py-2">{{ user.nombre }}</td>
                  <td class="px-4 py-2">{{ user.email }}</td>
                  <td v-if="!fechaCorte" class="px-4 py-2">
                    <EnvioMagicLinkUsuario v-if="canView('superadministrador','administradorevento','administrador')" :tokenUsuario="user.id_token"/>
                    <button @click="editUser(user.id_token)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded button-save">Editar</button>
                    <button @click="deleteUser(user.id_token, group.group_id)" class="ml-2 button-clear ">Eliminar</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="text-center py-2">
            <span v-if="canView('superadministrador','administrador')">
                Sin moderadores

            </span>
          </div>

          <!-- staff -->
          <div v-if="Number(group.staff) > 0">
                <h4 class="table-title">
                    <button v-if="!fechaCorte" @click="triggerFileInput(group.group_id, 'staff')" :class="{'bg-green-500 hover:bg-green-700': excelLoading.group_id !== group.group_id || excelLoading.category !== 'staff', 'bg-blue-500 hover:bg-blue-700': excelLoading.group_id === group.group_id && excelLoading.category === 'staff'}" class="mr-5  text-white font-bold py-1 px-3 rounded botton-excel">
                    <div v-if="excelLoading.group_id === group.group_id && excelLoading.category === 'staff'">
                        <!-- Procesando SVG -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-5 w-5 fill-current"><path d="M288 109.3L288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-242.7-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352l128 0c0 35.3 28.7 64 64 64s64-28.7 64-64l128 0c35.3 0 64 28.7 64 64l0 32c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64l0-32c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>
                    </div>
                    <div v-else class="button-excel">
                        <!-- Estado inicial SVG -->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="h-5 w-5 fill-current"><path d="M48 448L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z"/></svg><span>Cargar Excel</span>
                    </div>
                    </button>
                <span>Staff ({{ group.staff }})</span>
                <button v-if="!fechaCorte" @click="saveStaffMembers(group)" class="ml-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded button-save">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M48 96l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-245.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3L448 416c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l245.5 0c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8L320 184c0 13.3-10.7 24-24 24l-192 0c-13.3 0-24-10.7-24-24L80 80 64 80c-8.8 0-16 7.2-16 16zm80-16l0 80 144 0 0-80L128 80zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/></svg> <span>Guardar</span>
                </button>
                    
                    <input
                        type="file"
                        @change="handleFileChange($event, group.group_id, 'staff')"
                        style="display: none"
                        :id="'file-input-staff-' + group.group_id"
                    >
             
     
                    <button @click="toggleAccordion(group.group_id, 'staff')" class="rotate-button">
                    <svg :class="{'rotate-180': isAccordionOpen(group.group_id, 'staff')}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-4 w-4"><path d="M432 256l-192 192-192-192 19-19 173 173 173-173 19 19z"/></svg>
                    </button>


                </h4>
                <table v-show="isAccordionOpen(group.group_id, 'staff')" class="accordion-content min-w-full divide-y divide-gray-200">
                    <tbody>
                        <tr v-for="(staffMember, index) in group.staff_members.staff" :key="`staff-${index}`">

                            <td class="relative p-2 input-container w-1/4">
                                <label class="input-label">Nombres</label>
                                <input v-model="staffMember.nombres" class="input-field"  :disabled="fechaCorte">
                                <label class="input-label lower">Apellidos</label>
                                <input v-model="staffMember.apellidos" class="input-field  lower-select"  :disabled="fechaCorte">
                            </td>
                            
                            <td class="relative p-2 input-container w-1/6">
                                <label class="input-label">Documento</label>
                                <input v-model="staffMember.numero_de_documento" class="input-field"  :disabled="fechaCorte">
                                <label class="input-label lower">Tipo Documento</label>
                                <select v-model="staffMember.tipo_de_documento" class="input-field lower-select"  :disabled="fechaCorte">
                                    <option value="">-- Seleccione --</option>
                                    <option value="CC">CC - Cédula de Ciudadanía</option>
                                    <option value="CE">CE - Cédula de Extranjería</option>
                                    <option value="Pasaporte">Pasaporte</option>
                                </select>
                            </td>
                            <td class="relative p-2 input-container w-1/6">
                                <label class="input-label">Correo electrónico</label>
                                <input v-model="staffMember.correo_electronico" class="input-field"  :disabled="fechaCorte">
                                <label class="input-label lower">Teléfono</label>
                                <input :value="staffMember.telefono" @input="updatePhoneNumber(staffMember, $event.target.value)" class="input-field lower-select"  :disabled="fechaCorte">

                            </td>
                            <td class="relative p-2 input-container w-1/6">
                                <label class="input-label">Ciudad</label>
                                <input v-model="staffMember.ciudad" class="input-field"  :disabled="fechaCorte">
                                <label class="input-label lower">País</label>
                                <input v-model="staffMember.pais" class="input-field lower-select"  :disabled="fechaCorte">
                            </td>
                            <td class="relative p-2 input-container w-1/6">
                                <label class="input-label">Departamento</label>
                                <input v-model="staffMember.departamento" class="input-field"  :disabled="fechaCorte">
                                <label class="input-label lower">Categoría</label>
                                <input v-model="staffMember.categoria" class="input-field lower-select"  :disabled="fechaCorte">
                            </td>
                            <td class="relative p-2 input-container w-1/6 align-top" >
                                <!-- <label class="input-label">Estado</label> -->
                                <!-- <input v-model="staffMember.estado" class="input-field"> -->
                            </td>
                            <td v-if="!fechaCorte" class="p-2 w-1/12">
                                <button @click="showModal(group, 'staff', index)" class="button-clear">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M493.6 445c-11.2 5.3-24.5 3.6-34.1-4.4L288 297.7 288 416c0 12.4-7.2 23.7-18.4 29s-24.5 3.6-34.1-4.4L64 297.7 64 416c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 96C0 78.3 14.3 64 32 64s32 14.3 32 32l0 118.3L235.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S288 83.6 288 96l0 118.3L459.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S512 83.6 512 96l0 320c0 12.4-7.2 23.7-18.4 29z"/></svg> <span> Borrar</span>

                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>




       <!-- Conferencistas -->
                <div v-if="Number(group.conferencistas) > 0">
                    <h4 class="table-title">
                        <button v-if="!fechaCorte" @click="triggerFileInput(group.group_id, 'conferencistas')" :class="{'bg-green-500 hover:bg-green-700': excelLoading.group_id !== group.group_id || excelLoading.category !== 'staff', 'bg-blue-500 hover:bg-blue-700': excelLoading.group_id === group.group_id && excelLoading.category === 'staff'}" class="mr-5 button-excel text-white font-bold py-1 px-3 rounded">
                            <div v-if="excelLoading.group_id === group.group_id && excelLoading.category === 'staff'">
                                <!-- Procesando SVG -->
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-5 w-5 fill-current"><path d="M288 109.3L288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-242.7-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352l128 0c0 35.3 28.7 64 64 64s64-28.7 64-64l128 0c35.3 0 64 28.7 64 64l0 32c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64l0-32c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>
                            </div>
                            <div v-else class="button-excel">
                                <!-- Estado inicial SVG -->
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="h-5 w-5 fill-current"><path d="M48 448L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z"/></svg> <span>Cargar Excel</span>
                            </div>
                        </button>
                        <span>Expositores ({{ group.conferencistas }})</span>
                        <button v-if="!fechaCorte" @click="saveConferencistas(group)" class="ml-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded button-save">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M48 96l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-245.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3L448 416c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l245.5 0c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8L320 184c0 13.3-10.7 24-24 24l-192 0c-13.3 0-24-10.7-24-24L80 80 64 80c-8.8 0-16 7.2-16 16zm80-16l0 80 144 0 0-80L128 80zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/></svg> <span>Guardar</span>
                        </button>
                        <input
                            type="file"
                            @change="handleFileChange($event, group.group_id, 'conferencistas')"
                            style="display: none"
                            :id="'file-input-conferencistas-' + group.group_id"
                        >
        

                        <button @click="toggleAccordion(group.group_id, 'conferencistas')" class="rotate-button">
                        <svg :class="{'rotate-180': isAccordionOpen(group.group_id, 'conferencistas')}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-4 w-4"><path d="M432 256l-192 192-192-192 19-19 173 173 173-173 19 19z"/></svg>
                        </button>
                        

                    </h4>
                    <table v-show="isAccordionOpen(group.group_id, 'conferencistas')" class="accordion-content min-w-full divide-y divide-gray-200">
                        <tbody>
                            <tr v-for="(conferencista, index) in group.staff_members.conferencistas" :key="`conferencista-${index}`">
                                <td class="relative p-2 input-container w-1/4">
                                    <label class="input-label">Nombres</label>
                                    <input v-model="conferencista.nombres" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">Apellidos</label>
                                    <input v-model="conferencista.apellidos" class="input-field lower-select"  :disabled="fechaCorte">
                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <label class="input-label">Documento</label>
                                    <input v-model="conferencista.numero_de_documento" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">Tipo Documento</label>
                                    <select v-model="conferencista.tipo_de_documento" class="input-field lower-select"  :disabled="fechaCorte">
                                        <option value="">-- Seleccione --</option>
                                        <option value="CC">CC - Cédula de Ciudadanía</option>
                                        <option value="CE">CE - Cédula de Extranjería</option>
                                        <option value="Pasaporte">Pasaporte</option>
                                    </select>
                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <label class="input-label">Correo electrónico</label>
                                    <input v-model="conferencista.correo_electronico" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">Teléfono</label>
                                    <input :value="conferencista.telefono" @input="updatePhoneNumber(conferencista, $event.target.value)" class="input-field lower-select"  :disabled="fechaCorte">
                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <label class="input-label">Ciudad</label>
                                    <input v-model="conferencista.ciudad" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">País</label>
                                    <input v-model="conferencista.pais" class="input-field lower-select"  :disabled="fechaCorte">
                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <label class="input-label">Departamento</label>
                                    <input v-model="conferencista.departamento" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">Categoría</label>
                                    <input v-model="conferencista.categoria" class="input-field lower-select"  :disabled="fechaCorte">
                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <!-- <label class="input-label">Estado</label> -->
                                    <!-- <input v-model="conferencista.estado" class="input-field"> -->
                                </td>
                                <td v-if="!fechaCorte" class="p-2 w-1/12">
                                    <button @click="showModal(group, 'conferencistas', index)" class="button-clear">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M493.6 445c-11.2 5.3-24.5 3.6-34.1-4.4L288 297.7 288 416c0 12.4-7.2 23.7-18.4 29s-24.5 3.6-34.1-4.4L64 297.7 64 416c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 96C0 78.3 14.3 64 32 64s32 14.3 32 32l0 118.3L235.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S288 83.6 288 96l0 118.3L459.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S512 83.6 512 96l0 320c0 12.4-7.2 23.7-18.4 29z"/></svg> <span> Borrar</span>

                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                        <!-- Asistentes -->
                <div v-if="Number(group.asistentes) > 0">
                    <h4 class="table-title">
                        <button v-if="!fechaCorte" @click="triggerFileInput(group.group_id, 'asistentes')" :class="{'bg-green-500 hover:bg-green-700': excelLoading.group_id !== group.group_id || excelLoading.category !== 'staff', 'bg-blue-500 hover:bg-blue-700': excelLoading.group_id === group.group_id && excelLoading.category === 'staff'}" class="mr-5 button-excel text-white font-bold py-1 px-3 rounded">
                            <div v-if="excelLoading.group_id === group.group_id && excelLoading.category === 'staff'">
                                <!-- Procesando SVG -->
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="h-5 w-5 fill-current"><path d="M288 109.3L288 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-242.7-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352l128 0c0 35.3 28.7 64 64 64s64-28.7 64-64l128 0c35.3 0 64 28.7 64 64l0 32c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64l0-32c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>
                            </div>
                            <div v-else class="button-excel">
                                <!-- Estado inicial SVG -->
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="h-5 w-5 fill-current"><path d="M48 448L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z"/></svg> <span>Cargar Excel</span>
                            </div>
                        </button>

                        <span>Asistentes ({{ group.asistentes }})</span>
                        <button v-if="!fechaCorte" @click="saveAsistentes(group)" class="ml-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded button-save">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M48 96l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-245.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3L448 416c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l245.5 0c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8L320 184c0 13.3-10.7 24-24 24l-192 0c-13.3 0-24-10.7-24-24L80 80 64 80c-8.8 0-16 7.2-16 16zm80-16l0 80 144 0 0-80L128 80zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/></svg> <span>Guardar</span>
                        </button>
                        <input
                            type="file"
                            @change="handleFileChange($event, group.group_id, 'asistentes')"
                            style="display: none"
                            :id="'file-input-asistentes-' + group.group_id"
                        >

                        <button @click="toggleAccordion(group.group_id, 'asistentes')" class="rotate-button">
                            <svg :class="{'rotate-180': isAccordionOpen(group.group_id, 'asistentes')}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-4 w-4"><path d="M432 256l-192 192-192-192 19-19 173 173 173-173 19 19z"/></svg>
                        </button>
                    </h4>
                    <table v-show="isAccordionOpen(group.group_id, 'asistentes')" class="accordion-content min-w-full divide-y divide-gray-200">
                        <tbody>
                            <tr v-for="(asistente, index) in group.staff_members.asistentes" :key="`asistente-${index}`">
                                <td class="relative p-2 input-container w-1/4">
                                    <label class="input-label">Nombres</label>
                                    <input v-model="asistente.nombres" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">Apellidos</label>
                                    <input v-model="asistente.apellidos" class="input-field lower-select"  :disabled="fechaCorte">
                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <label class="input-label">Documento</label>
                                    <input v-model="asistente.numero_de_documento" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">Tipo Documento</label>
                                    <select v-model="asistente.tipo_de_documento" class="input-field lower-select"  :disabled="fechaCorte">
                                        <option value="">-- Seleccione --</option>
                                        <option value="CC">CC - Cédula de Ciudadanía</option>
                                        <option value="CE">CE - Cédula de Extranjería</option>
                                        <option value="Pasaporte">Pasaporte</option>
                                    </select>
                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <label class="input-label">Correo electrónico</label>
                                    <input v-model="asistente.correo_electronico" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">Teléfono</label>
                                    <input :value="asistente.telefono" @input="updatePhoneNumber(asistente, $event.target.value)" class="input-field lower-select"  :disabled="fechaCorte">

                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <label class="input-label">Ciudad</label>
                                    <input v-model="asistente.ciudad" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">País</label>
                                    <input v-model="asistente.pais" class="input-field lower-select"  :disabled="fechaCorte">
                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <label class="input-label">Departamento</label>
                                    <input v-model="asistente.departamento" class="input-field"  :disabled="fechaCorte">
                                    <label class="input-label lower">Categoría</label>
                                    <input v-model="asistente.categoria" class="input-field lower-select"  :disabled="fechaCorte">
                                </td>
                                <td class="relative p-2 input-container w-1/6">
                                    <!-- <label class="input-label">Estado</label> -->
                                    <!-- <input v-model="asistente.estado" class="input-field"> -->
                                </td>
                                <td v-if="!fechaCorte" class="p-2 w-1/12">
                                    <button @click="showModal(group, 'asistentes', index)" class="button-clear">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M493.6 445c-11.2 5.3-24.5 3.6-34.1-4.4L288 297.7 288 416c0 12.4-7.2 23.7-18.4 29s-24.5 3.6-34.1-4.4L64 297.7 64 416c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 96C0 78.3 14.3 64 32 64s32 14.3 32 32l0 118.3L235.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S288 83.6 288 96l0 118.3L459.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S512 83.6 512 96l0 320c0 12.4-7.2 23.7-18.4 29z"/></svg> <span> Borrar</span>

                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>



            <button v-if="!fechaCorte" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded" @click="saveAllMembers(group)">Guardar Todos</button>
        </div>
      </div>
      <div class="flex justify-between items-center mt-4" v-if="pager.totalPages > 1">
        <button :disabled="pager.currentPage === 1" 
                @click="fetchGroups(pager.currentPage - 1)"
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50">
            Anterior
        </button>
        <span>Página {{ pager.currentPage }} de {{ pager.totalPages }}</span>
        <button :disabled="pager.currentPage === pager.totalPages"
                @click="fetchGroups(pager.currentPage + 1)"
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50">
            Siguiente
        </button>
        </div>
         <!-- Modal de confirmación -->
        <div v-if="showConfirmationModal" class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>

            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div class="sm:flex sm:items-start">
                <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg class="h-6 w-6 text-yellow-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M12 18h.01"></path>
                    </svg>
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-2">
                        Atención
                        </h3>
                        <br>
                        <p>
                        Cargando <b>{{ selectedCategory === 'conferencistas' ? 'Expositores' : selectedCategory }}</b>
                        </p>
                        <div class="mt-2">
                        <p class="text-sm text-gray-500">
                            Al cargar una base de datos se reemplazarán los datos de los {{ selectedCategory === 'conferencistas' ? 'Expositores' : selectedCategory }} que ya tenga guardados para esta lista de usuarios.
                        </p>
                        </div>

                </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button @click="confirmUpload" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                    OK, CARGAR
                </button>
                <button @click="cancelUpload" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                    CANCELAR
                </button>
                </div>
            </div>
            </div>
             <!-- Modal de Confirmación -->
            </div>
        <ModalConfirm
            :is-visible="isModalVisible"
            title="Confirmación de Borrado"
            message="¿Estás seguro de que deseas borrar este miembro?"
            @confirm="clearMember"
            @cancel="closeModal"
        ></ModalConfirm>
    </div>
  </template>

<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import EditGroupModal from '@/components/modals/groupModal/EditGroupModal.vue';
import CreateGroupModal from '@/components/modals/groupModal/CreateGroupModal.vue';
import CargaGrupos from '@/components/CargaGrupos/CargaGrupos.vue';
import { useToast } from "vue-toastification";
import DownloadButton from '@/components/DownloadButton.vue';
import AgregarAdministradorEvento from '@/components/administracion/AgregarAdministradorEvento.vue';
import AgregarSuperAdministrador from '@/components/administracion/AgregarSuperAdministrador.vue';
import EnvioMagicLinksEvento from '@/components/administracion/EnvioMagicLinksEvento.vue';
import EnvioMagicLinksGrupo from '@/components/administracion/EnvioMagicLinksGrupo.vue';
import EnvioMagicLinkUsuario from '@/components/administracion/EnvioMagicLinkUsuario.vue';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';
// import { createLogger } from 'vuex';
// import { createLogger } from 'vuex';

export default {
  name: 'ListaGruposUsuarios',
  props: {
    eventoToken: {
      type: String,
      required: true
    }
  },
  setup() {      
      const toast = useToast();
      return { toast }
    },
  data() {
    return {
      groups: [],
      loading: true,
      role: null,
      excelLoading: {
        group_id: null,
        category: ''
      },
      expandedGroup: null,
      expandedCategory: null,      
      pager: {
        currentPage: 1,
        totalPages: 0
      },
      showConfirmationModal: false,
      selectedGroupId: null,
      selectedCategory: '',
      selectedFile: null,
      isModalVisible: false,
      currentGroup: null,
      currentCategory: null,
      currentIndex: null,
      showModalButtons: false,
      fechaResponse: null,
      fechaCorte: false,
    };
  },
  components: {
    CreateGroupModal,
    EditGroupModal,
    CargaGrupos,
    DownloadButton,
    AgregarAdministradorEvento, // Añade aquí tu nuevo componente
    AgregarSuperAdministrador, // Añade aquí tu nuevo componente
    ModalConfirm,
    EnvioMagicLinksEvento,
    EnvioMagicLinksGrupo,
    EnvioMagicLinkUsuario
  },
  computed: {      
    roleToken() {
      return this.$store.state.roleToken;
    },
  },
  watch: {
    'pager.currentPage'(newPage) {
        this.fetchGroups(newPage);
    }
  },

  methods: {
    triggerFileInput(groupId, category) {
        this.selectedGroupId = groupId;
        this.selectedCategory = category;
        const fileInput = document.getElementById('file-input-' + category + '-' + groupId);
        this.excelLoading = { group_id: groupId, category: category }; // Configurar la carga
        fileInput.click();
    },
    
    toggleAccordion(groupId, category) {
        if (this.expandedGroup === groupId && this.expandedCategory === category) {
        this.expandedGroup = null;
        this.expandedCategory = null;
        } else {
        this.expandedGroup = groupId;
        this.expandedCategory = category;
        }
    },

    isAccordionOpen(groupId, category) {
        return this.expandedGroup === groupId && this.expandedCategory === category;
    },
// Dentro de un método
fetchGroups(page = 1) {
    const token = this.$store.state.user.token;
    axios.post(`/api/usuarios/${this.eventoToken}`, { 
        Authorization: `${token}`, 
        page: page 
    })
    .then(response => {
        this.groups = response.data.groups;
        this.pager = response.data.pager;
        const fechaParts = response.data.fecha.split(' ')[0].split('-'); // Divide la fecha en partes [año, mes, día]
        const fechaDeCorte = new Date(fechaParts[0], fechaParts[1] - 1, fechaParts[2]); // Crea la fecha de corte

        const fechaActual = new Date();
        fechaActual.setHours(0, 0, 0, 0); // Normaliza la fecha actual a medianoche
        console.log(fechaDeCorte)
        console.log(fechaActual)

        this.fechaCorte = fechaActual >= fechaDeCorte;
        if (this.fechaCorte) {
            this.toast.info("Debido al paso de la fecha de corte no se pueden editar los registros");
        }
        this.loading = false;
        this.fillExistingData(this.groups);
    })
    .catch(error => {
        console.error('Error fetching groups:', error);
        this.loading = false;
        if (error.response && error.response.status === 403) {
            this.$router.push({ name: 'Forbidden' });
        }
    });
},






    initializeMembers(group, category) {
        const memberCount = parseInt(group[category] || 0);
        const existingMembers = group.staff_members && group.staff_members[category] ? group.staff_members[category] : [];

        return Array.from({length: memberCount}, (_, index) => {
            return existingMembers[index] || {
                nombres: '',
                apellidos: '',
                numero_de_documento: '',
                correo_electronico: '',
                tipo_de_documento: '',
                telefono: '',
                ciudad: '',
                pais: '',
                departamento: '',
                categoria: '',
                escarapela: this.getEscarapela(category),
                estado: ''
            };
        });
    },

    getEscarapela(category) {
        switch(category) {
            case 'staff': return 'Staff';
            case 'conferencistas': return 'Conferencista';
            case 'asistentes': return 'Asistente';
            default: return '';
        }
    },

    fillExistingData(groupData) {
        this.groups = groupData.map(group => ({
            ...group,
            staff_members: {
                staff: this.initializeMembers(group, 'staff'),
                conferencistas: this.initializeMembers(group, 'conferencistas'),
                asistentes: this.initializeMembers(group, 'asistentes')
            }
        }));
    },


    cleanPhoneNumber(value) {
        // Filtra el valor permitiendo solo números y los caracteres específicos
        return value.replace(/[^0-9()\-[\]+/]/g, '');
    },
    updatePhoneNumber(member, newValue) {
        member.telefono = this.cleanPhoneNumber(newValue);
    },
    getMember(group, category, index, field) {
        // Initialize the category if it does not exist
        if (!group.staff_members[category][index]) {
        this.$set(group.staff_members[category], index, { nombres: '', apellidos: '' });
        }
        return group.staff_members[category][index][field];
    },
    showModal(group, category, index) {
      this.currentGroup = group;
      this.currentCategory = category;
      this.currentIndex = index;
      this.isModalVisible = true;
    },
    clearMember() {
        if (this.currentGroup && this.currentCategory !== null && this.currentIndex !== null) {
        const member = this.currentGroup.staff_members[this.currentCategory][this.currentIndex];
       
            if (member) {
                member.nombres = '';
                member.apellidos = '';
                member.numero_de_documento = '';
                member.correo_electronico = '';
                member.tipo_de_documento = '';  
                member.telefono = '';
                member.ciudad = '';
                member.pais = '';
                member.departamento = '';
                member.categoria = '';
                member.estado = '';
            }
        }
        this.closeModal();      
    },

    closeModal() {
      this.isModalVisible = false;
    },
    saveAllMembers(group) {
        axios.post(`/api/staffs/${group.group_id}`, {
            staff: group.staff_members.staff,
            conferencistas: group.staff_members.conferencistas,
            asistentes: group.staff_members.asistentes
        }).then(() => {            
            this.toast.success("Guardado correctamente");
            // Llamar a fetchGroups aquí para recargar datos podría ayudar
            // this.fetchGroups();
        }).catch(error => {
            console.error('Error saving members:', error);
        });
    },

    saveStaffMembers(group) {
        return axios.post(`/api/staff/save-staff/${group.group_id}`, {
            staff: group.staff_members.staff
        }).then(() => {
            this.toast.success('Staff guardado correctamente');
        }).catch(error => {
            console.error('Error al guardar el staff:', error);
            throw error; // Asegúrate de propagar el error para manejarlo en prepareDataForUpload
        });
    },


    saveConferencistas(group) {
        return axios.post(`/api/staff/save-conferencistas/${group.group_id}`, {
            conferencistas: group.staff_members.conferencistas
        }).then(() => {
            this.toast.success('Expositores guardados correctamente');
        }).catch(error => {
            console.error('Error al guardar conferencistas:', error);
            throw error;
        });
    },


    saveAsistentes(group) {
        return axios.post(`/api/staff/save-asistentes/${group.group_id}`, {
            asistentes: group.staff_members.asistentes
        }).then(() => {
            this.toast.success('Asistentes guardados correctamente');
        }).catch(error => {
            console.error('Error al guardar asistentes:', error);
            throw error;
        });
    },

    handleFileChange(event, groupId, category) {
    const file = event.target.files[0];
    if (!file) return;
    this.selectedFile = file;
    this.selectedGroupId = groupId;
    this.selectedCategory = category;
    this.showConfirmationModal = true;
    },

    confirmUpload() {
        this.showConfirmationModal = false;
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
            this.prepareDataForUpload(jsonData, this.selectedGroupId, this.selectedCategory);
        };
        reader.readAsArrayBuffer(this.selectedFile);
    },


    cancelUpload() {
      this.showConfirmationModal = false;
      this.selectedFile = null;
      this.selectedGroupId = null;
      this.selectedCategory = '';
    },
    async prepareDataForUpload(data, groupId, category) {
        // Quitar el encabezado y limitar los registros según la capacidad del grupo
        const group = this.groups.find(g => g.group_id === groupId);
        const capacity = category === 'staff' ? group.staff : category === 'conferencistas' ? group.conferencistas : group.asistentes;
        const formattedData = data.slice(1, capacity + 1).map(entry => ({
            nombres: entry[0] || '',
            apellidos: entry[1] || '',
            numero_de_documento: entry[2] || '',
            correo_electronico: entry[3] || '',
            tipo_de_documento: entry[4] || '',
            telefono: entry[5] || '',
            ciudad: entry[6] || '',
            pais: entry[7] || '',
            departamento: entry[8] || '',
            categoria: entry[9] || ''
        }));

        try {
            if (category === 'staff') {
                await this.saveStaffMembers({ group_id: groupId, staff_members: { staff: formattedData } });
            } else if (category === 'conferencistas') {
                await this.saveConferencistas({ group_id: groupId, staff_members: { conferencistas: formattedData } });
            } else if (category === 'asistentes') {
                await this.saveAsistentes({ group_id: groupId, staff_members: { asistentes: formattedData } });
            }
            // Llamar a fetchGroups solo después de guardar datos vía Excel
            this.fetchGroups();
            
            document.getElementById('file-input-'+category+ '-' + groupId).value = null;
            this.excelLoading = { group_id: null, category: '' }; // Resetear la carga
        } catch (error) {
            console.error('Error al guardar datos:', error);
        }
    },

   
    addUser(groupId) {
      this.$router.push(`/dashboard/usuarios/agregar-usuario/${groupId}`);
    },
    editUser(userId) {
      const url = `/dashboard/user/edit/${userId}`;
      window.open(url, '_blank');
    },
    deleteUser(userId, groupId) {
        const token = this.$store.state.user.token;
        axios.post(`/api/user/delete/${userId}`, { grupo: groupId, Authorization: `${token}` })
        .then(response => {
        // console.log("Usuario eliminado correctamente:", response);
            if (response.data && response.data.success) {
                this.toast.success(response.data.success);
                // Filtrar el usuario eliminado del estado local de grupos
                this.groups = this.groups.map(group => {
                    if (group.group_id === groupId) {
                        // Filtrar usuarios para quitar el eliminado
                        group.users = group.users.filter(user => user.id_token !== userId);
                    }
                    return group;
                });
            } else {
                this.toast.success('Usuario eliminado exitosamente');
            }
        })
        .catch(error => {
            console.error("Error al eliminar usuario:", error);
            let errorMessage = 'Error al eliminar usuario'; // Mensaje por defecto
            if (error.response && error.response.data) {
                errorMessage = error.response.data.error || error.response.data.message || errorMessage;
            } else {
                errorMessage = error.message || errorMessage; // Usar el mensaje de error de JavaScript si no hay una respuesta HTTP
            }
            this.toast.error(errorMessage);
        });
    },


    handleGroupUpdated(updatedGroup) {
        const updatedIndex = this.groups.findIndex(group => group.group_id === updatedGroup.group_id);
        if (updatedIndex !== -1) {
            this.groups[updatedIndex] = {...this.groups[updatedIndex], ...updatedGroup};
            this.toast.success('Grupo actualizado correctamente!');
        }
    },

    handleGroupDeleted(groupId) {
      this.groups = this.groups.filter(group => group.group_id !== groupId);
    },
    async validateRole() {
      // console.log("Role token:", this.roleToken);

      if (!this.roleToken) {
        this.roleLoaded = true;
        return;
      }
      try {
        const response = await axios.post('/api/verify-role', {
          roleToken: this.roleToken
        });
        // console.log('Role response:', response.data); // Agregar un log para depuración
        this.role = response.data.role;
      } catch (error) {
        // console.error('Error verifying role:', error);
        this.role = null;
      } finally {
        this.roleLoaded = true;
      }
    },
    canView(...expectedRoles) {
        if (!this.roleLoaded) {
            return false;
        }
        const roleHierarchy = {
            'usuario': 1,
            'administrador': 2,
            'administradorevento': 3,
            'superadministrador': 4
        };

        return expectedRoles.some(role => roleHierarchy[this.role] >= roleHierarchy[role]);
    }
  },
  mounted() {
    this.validateRole();
    this.fetchGroups();
  }
}
</script>


<style scoped>
.container {
  flex-direction: column;
}
.botones {
  width: 350px;
}
.titulo-grupo {
  display: flex;
  align-items: center;
  div {
    margin-right: 20px;
  }
}
.table-responsive {
  overflow-x: auto;
}
th, td {
  min-width: 120px; /* Ajusta según sea necesario */
  max-width: 200px; /* Ajusta según sea necesario */
}
input, select {
  width: 100%;
  box-sizing: border-box; 
}
button svg {
    fill: #fff;
}
table th {
    text-align: center;
}
.accordion-content tbody tr{
    border-radius: 7px;
    background: rgba(224, 224, 224, 0.05);    
    padding: 0px;
    display: block;
    margin: 15px auto;
    padding: 15px 5px 5px 5px;
    box-shadow: 2px 2px 10px rgba(155,155,155,0.5);
}

.table-title {
    font-size: 1rem; /* 16px */
    font-weight: 600;
    padding-bottom: 1rem; /* 16px */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    position: relative;
    background: rgba(155,155,155, 0.1);
    border-radius: 15px;
    margin-bottom: 20px;
}
.table-title span{
    display: inline-block;
    min-width: 200px;
}

.input-container {
    position: relative;
    padding: 0.5rem; /* 8px */
}

.input-label {
    position: absolute;
    top: -12px;
    left: 5px;
    font-size: 0.75rem; /* 12px */
    color: #4b5563; /* Gray-600 */
}

.input-field {
    padding-left: 2px;
    
    width: 100%;
    border: 1px solid #d1d5db; /* Gray-300 */
    border-radius: 0.375rem; /* 6px */
}

.lower {
    top: 48px; /* adjust based on your layout */
}

.lower-select {
    margin-top: 2.2rem; /* adjust based on your layout */
}

.button-clear {
    min-width: 20px;
    background-color: #f94e3b; /* Orange-500 */
    color: white;
    padding: 0.5rem;
    border-radius: 0.375rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.15s ease-in-out;
}
.button-excel {
    min-width: 20px;    
    color: white;
    /* padding: 0.5rem; */
    /* border-radius: 0.375rem; */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.15s ease-in-out;
}
.button-excel span{
    margin-left: 7px;
    font-size: 0.9em;
    min-width: 100px;
    /* max-width: 100px; */
}
.button-save {
    min-width: 20px;    
    color: white;
    padding: 0.5rem;
    /* border-radius: 0.375rem; */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.15s ease-in-out;
}
.button-save span{
    margin-left: 7px;
    min-width: 66px;
    font-size: 0.9em;
    /* max-width: 100px; */
}
.button-clear span{
    margin-left: 7px;
}

.button-clear:hover {
    background-color: #be0303; /* Orange-600 */
}
button svg {
    /* min-height: 25px; */
    min-width: 20px;
}
.button-excel {
    min-height: 31px;
    /* margin-left: 10px; */
}
.bg-green-500 { background-color: #10b981; }
.hover:bg-green-700:hover { background-color: #059669; }
.bg-blue-500 { background-color: #3b82f6; }
.hover:bg-blue-700:hover { background-color: #1e40af; }
.rotate-button {
    min-height: 31px;
    min-width: 30px;
    box-shadow: 1px 2px 8px #999999;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    position: absolute;
    right: 5vw;
    background: #fff;
    
} 
.rotate-button svg {
  transition: transform 0.3s ease-in-out;
  fill: #000;
}

.rotate-180 {
  transform: rotate(180deg);
}

.accordion-content {
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px;
  margin: 0 5px;
  background-color: #efefef;
  border: none;
  cursor: pointer;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-trigger-button {
    background: transparent;
    border: none;
    cursor: pointer;
    /* border: 1px solid #000; */
    padding: 15px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 8px rgba(0,0,0,0.7);
    position: fixed;
    z-index: 30;
    top: 7px;
    left: 49vw;
    right: auto;
    /* margin: auto; */
    transition: all 0.2s;
}
.modal-trigger-button:hover{
    background: #2959a6;
    box-shadow: 0 0 8px rgba(255,255,255,0.7);
    
}
.modal-trigger-button:hover svg{
    fill: #fff;    
}

.modal-trigger-button svg {
    transition: all 0.2s;
    fill: gray;
    transform: scale(2);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content {
  /* background: white; */
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
