<template>
  <div class="check-groups">
    <h1 class="text-2xl font-bold text-center mt-6">Resumen de Grupos</h1>
    <div class="mt-3 mb-6">
      <button class="button-excel" @click="exportToExcel">Descargar usuarios registrados</button>
    </div>
    <!-- Resumen General -->
    <div class="general-summary mb-8">
      <p>Cantidad de grupos: {{ totalGroups }}</p>
      <p>Cantidad de usuarios completos: {{ totalCompleteUsers }}</p>
      <p>Cantidad de usuarios incompletos: {{ totalIncompleteUsers }}</p>
      <p>Cantidad de grupos con todos los usuarios completos: {{ groupsComplete }}</p>
      <p>Cantidad de grupos con al menos un usuario completo: {{ groupsWithAtLeastOneComplete }}</p>
      <p>Cantidad de grupos con usuarios incompletos: {{ groupsIncomplete }}</p>
    </div>

    <!-- Gráficas -->
     <div class="contenedor-graficas mb-20">
      <PieChart :chart-data="completeGroupsChartData" chart-title="Grupos Completos vs Restantes"/>
      <PieChart :chart-data="groupsAtLeastOneCompleteChartData" chart-title="Grupos con al menos un usuario completo"/>
      <PieChart :chart-data="userCompletenessChartData" chart-title="Usuarios Completos vs Incompletos"/>
      <PieChart :chart-data="qrChartData" chart-title="Usuarios con datos para generar QR"/>
    </div>

    <!-- Tabla de Grupos -->
    <table class="min-w-full table-auto text-left">
      <thead>
        <tr>
          <th>Nombre del grupo</th>
          <th>Staffs</th>
          <th>Asistentes</th>
          <th>Expositores</th>
          <th>Total Registrados</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(group, id) in groups" :key="id">
          <td>{{ group.datadelgrupo.name }}</td>
          <td>
              <span v-if="group.datadelgrupo.cantidad_staff > 0">
                {{ countComplete(group.staffs.usuarios, 'Staff') }} / {{ group.datadelgrupo.cantidad_staff }}
              </span>
              <span v-else>
                  0
              </span>
          </td>
          <td>
              <span v-if="group.datadelgrupo.cantidad_asistente > 0">
                {{ countComplete(group.staffs.usuarios, 'Asistente') }} / {{ group.datadelgrupo.cantidad_asistente }}
              </span>
              <span v-else>
                  0
              </span>
          </td>
          <td>
              <span v-if="group.datadelgrupo.cantidad_conferencista > 0">
                {{ countComplete(group.staffs.usuarios, 'Conferencista') }} / {{ group.datadelgrupo.cantidad_conferencista }}
              </span>
              <span v-else>
                  0
              </span>
          </td>
          <td>{{ totalCompleteUsersGroup(group.staffs.usuarios) }} / {{ totalAllowedUsersGroup(group) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import PieChart from '@/components/PieChart'; // Ensure PieChart is correctly imported

export default {
  name: "CheckGroups",
  components: {
    PieChart
  },
  props: {
    eventoToken: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      groups: {}
    };
  },
  computed: {
    totalGroups() {
      return Object.keys(this.groups).length;
    },
    totalCompleteUsers() {
      return this.computeTotalUsers('complete');
    },
    totalIncompleteUsers() {
      return this.computeTotalUsers('incomplete');
    },
    groupsComplete() {
      return this.computeGroupCounts('complete');
    },
    groupsWithAtLeastOneComplete() {
      return this.computeGroupCounts('atLeastOneComplete');
    },
    groupsIncomplete() {
      return this.computeGroupCounts('incomplete');
    },
    completeGroupsChartData() {
      return {
        labels: [`Grupos Completos: ${this.groupsComplete}`, `Grupos Restantes: ${ this.totalGroups - this.groupsComplete}`],
        datasets: [{
          data: [this.groupsComplete, this.totalGroups - this.groupsComplete],
          backgroundColor: ['#59B4F1', '#d5d5d5'],
          hoverBackgroundColor: ['#59B4F1', '#d5d5d5']
        }]
      };
    },
    groupsAtLeastOneCompleteChartData() {
      return {
        labels: [`Grupos con al menos un usuario completo: ${this.groupsWithAtLeastOneComplete}`, `Otros grupos: ${this.totalGroups - this.groupsWithAtLeastOneComplete}`],
        datasets: [{
          data: [this.groupsWithAtLeastOneComplete, this.totalGroups - this.groupsWithAtLeastOneComplete],
          backgroundColor: ['#AAD6F2', '#d5d5d5'],
          hoverBackgroundColor: ['#AAD6F2', '#d5d5d5']
        }]
      };
    },
    userCompletenessChartData() {
      return {
        labels: [`Usuarios Completos: ${this.totalCompleteUsers}`, `Usuarios Incompletos: ${this.totalIncompleteUsers}`],
        datasets: [{
          data: [this.totalCompleteUsers, this.totalIncompleteUsers],
          backgroundColor: ['#59B4F1', '#d5d5d5'],
          hoverBackgroundColor: ['#59B4F1', '#d5d5d5']
        }]
      };
    },
    usuariosConDatosQR() {
      let count = 0;
      Object.values(this.groups).forEach(group => {
        group.staffs.usuarios.forEach(user => {
          if (user.nombres && user.correo_electronico) {
            count++;
          }
        });
      });
      return count;
    },
    qrChartData() {
      const totalUsuarios = Object.values(this.groups).reduce((acc, group) => acc + group.staffs.usuarios.length, 0);
      const usuariosConQR = this.usuariosConDatosQR;
      return {
        labels: [`Con datos para QR: ${usuariosConQR}`, `Incompletos: ${totalUsuarios - usuariosConQR}`],
        datasets: [{
          data: [usuariosConQR, totalUsuarios - usuariosConQR],
          backgroundColor: ['#AAD6F2', '#d5d5d5'],
          hoverBackgroundColor: ['#AAD6F2', '#d5d5d5']
        }]
      };
    }
  },
  methods: {
    fetchGroups() {
      axios.post('/api/list/members-staff', { token_evento: this.eventoToken })
        .then(response => {
          this.groups = response.data.evento;
        })
        .catch(error => {
          console.error('Error fetching members:', error);
        });
    },
    countComplete(users, category) {
        return users.filter(user => user.escarapela === category && this.isUserComplete(user)).length;
      },
    totalCompleteUsersGroup(users) {
      return users.filter(this.isUserComplete).length;
    },
    totalAllowedUsersGroup(group) {
      return parseInt(group.datadelgrupo.cantidad_staff) + parseInt(group.datadelgrupo.cantidad_asistente) + parseInt(group.datadelgrupo.cantidad_conferencista);
    },
    computeTotalUsers(type) {
      return Object.values(this.groups).reduce((total, group) => {
        const count = group.staffs.usuarios.filter(user => type === 'complete' ? this.isUserComplete(user) : !this.isUserComplete(user)).length;
        return total + count;
      }, 0);
    },
    computeGroupCounts(type) {
      return Object.values(this.groups).reduce((count, group) => {
        const allComplete = group.staffs.usuarios.every(this.isUserComplete);
        const anyIncomplete = group.staffs.usuarios.some(user => !this.isUserComplete(user));
        const anyComplete = group.staffs.usuarios.some(this.isUserComplete);
        if (type === 'complete' && allComplete) return count + 1;
        if (type === 'incomplete' && anyIncomplete) return count + 1;
        if (type === 'atLeastOneComplete' && anyComplete) return count + 1;
        return count;
      }, 0);
    },
    isUserComplete(user) {
      const requiredFields = ['nombres', 'apellidos', 'numero_de_documento', 'correo_electronico', 'telefono', 'ciudad', 'pais', 'departamento', 'categoria'];
      return requiredFields.every(field => user[field] && user[field].trim() !== '');
    },
    exportToExcel() {

      const data = this.prepareDataForExcel();
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

      // Write the file
      XLSX.writeFile(workbook, 'report.xlsx');
    },
    prepareDataForExcel() {
  // Verifica que groups no sea undefined o null
  if (!this.groups || Object.keys(this.groups).length === 0) {
    console.error("No group data available");
    return [];
  }

  let rows = [];
  Object.keys(this.groups).forEach(groupId => {
    const group = this.groups[groupId];
    if (group.staffs && Array.isArray(group.staffs.usuarios)) {
      group.staffs.usuarios.forEach(user => {
        // Asegúrate de que user tenga todos los campos necesarios antes de agregarlo a rows
        if (user.nombres && user.correo_electronico) {
          rows.push({
            grupo: group.datadelgrupo.name,
            nombres: user.nombres,
            apellidos: user.apellidos,
            numero_de_documento: user.numero_de_documento,
            tipo_de_documento: user.tipo_de_documento,
            correo_electronico: user.correo_electronico,
            telefono: user.telefono,
            ciudad: user.ciudad,
            pais: user.pais,
            departamento: user.departamento,
            categoria: user.categoria,
            escarapela: user.escarapela,
            autorizado: user.autorizado
          });
        }
      });
    }
  });
  return rows;
}

  },
  mounted() {
    this.fetchGroups();
  }
};
</script>
  <style scoped>
  .check-groups {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    justify-content: flex-start;
    margin-top: 30px;
    padding-bottom: 120px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 15px;
    overflow: hidden;
  }
  
  th, td {
    border-top: 1px solid #ccc;
    background: #fff;
    padding: 8px;
    text-align: center;
  }
  th {
    background-color: #1f2937;
    color: #fff;
  }
  .button-excel {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    /* margin: 4px 2px 4px 2px; */
    cursor: pointer;
    border-radius: 10px;
  }
  .button-excel:hover{
    background-color: #378139; /* Green */

  }
  .contenedor-graficas{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  </style>
  