<template>
    <div class="contenedorLinkUser">
      <!-- Botón para disparar la generación del enlace mágico -->
      <button @click="generateMagicLink" class="botton-link bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded">
        Generar Magic Link
      </button>
  
      <!-- Modal para mostrar el magic link y los mensajes de estado del correo -->
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <h3 class="modal-title">{{ correo }}</h3>
          <p>{{ magicLink }}</p>
          <button @click="copyToClipboard" class="copy-button">Copiar Link</button>
          <p v-if="mailStatus === 'ok'">Link enviado correctamente por correo.</p>
          <p v-else>No fue posible enviar correo, comparta este link con el usuario.</p>
          <button @click="closeModal" class="close-button">Cerrar</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useToast } from 'vue-toastification';
  
  export default {
    props: {
      tokenUsuario: {
        type: String,
        required: true
      }
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        showModal: false,
        user: {},
        magicLink: '',
        mailStatus: '',
        correo: ''
      };
    },
    methods: {
      generateMagicLink() {
        axios.post('/api/generate-magic-link/user', { idToken: this.tokenUsuario })
          .then(response => {
            this.user = response.data.link;
            this.magicLink = response.data.link.magic_link;
            this.mailStatus = response.data.link.mail;
            this.correo = response.data.link.correo;
            this.showModal = true;
          })
          .catch(error => {
            console.error('Error al generar magic link para el usuario:', error);
            this.toast.error('Error al procesar la solicitud.');
          });
      },
      copyToClipboard() {
        navigator.clipboard.writeText(this.magicLink)
          .then(() => {
            this.toast.success('Link copiado al portapapeles.');
          })
          .catch(err => {
            console.error('Error al copiar el texto:', err);
            this.toast.error('Error al copiar el link.');
          });
      },
      closeModal() {
        this.showModal = false;
      }
    }
  }
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .copy-button, .close-button {
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .copy-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .close-button {
    background-color: #f44336;
    color: white;
  }
  
  .modal-title {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  .contenedorLinkUser{
    display: inline-block;
    margin-right: 10px;
  }
  .botton-link {
    font-size: 0.9em;
  }
  </style>
  