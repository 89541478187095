<template>
    <div v-if="isAuthorized" class="container mx-auto px-4 py-6 flex flex-wrap justify-center gap-4">
        <div class="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-md md:flex-1 md:min-h-[500px]">
            <p class="text-2xl font-bold mb-5">Crear cuenta en el grupo</p>
            <form @submit.prevent="handleRegister" class="flex flex-col gap-4">
                <div v-if="!emailExists">
                    <div>
                        <label class="font-medium text-gray-700">Nombre</label>
                        <input v-model="nombre" type="text" placeholder="Nombre y apellido" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" >
                    </div>                                
                </div>                                
                <div>
                    <label class="font-medium text-gray-700">Dirección de email</label>
                    <input v-model="email" type="email"  @input="debouncedCheckEmail" placeholder="ejemplo@mail.com" :class="emailClass" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
                    <p v-if="emailExists" class="mt-5 text-green-700 text-sm">Este usuario ya existe, solo con el correo podrás asignar este usuario a este grupo.</p>
                </div>                
                <div v-if="!emailExists">
                    <div>
                        <label class="font-medium text-gray-700">Contraseña</label>
                        <input v-model="password" type="password" placeholder="Debe tener al menos 7 caracteres" :class="passwordClass" class="mt-1 w-full border-gray-300 rounded-md shadow-sm">
                    </div>
                    <div class="mt-3">
                        <label class="font-medium text-gray-700">Confirmar contraseña</label>
                        <input v-model="confirmPassword" type="password" :class="passwordClass" class="mt-1 w-full border-gray-300 rounded-md shadow-sm">
                    </div>        
                    <div class="mt-3">
                        <label class="font-medium text-gray-700">Rol</label>
                        <select v-model="role" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
                            <option value="">-- Selecciona un rol --</option>
                            <option v-for="option in availableRoles" :value="option.value" :key="option.value">{{ option.text }}</option>
                        </select>
                    </div>        
                </div>
                <button type="submit" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Crear usuario</button>
            </form>             
        </div>
        <div class="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-md md:flex-1 md:min-h-[500px]">
            <p class="text-2xl font-bold mb-5">Crear link de registro</p>
            <form @submit.prevent="handleCreateKey" class="flex flex-col gap-4">
                <div>
                    <label class="font-medium text-gray-700">Rol</label>
                    <select v-model="selectedRoleKey" required class="mt-1 w-full border-gray-300 rounded-md shadow-sm">
                        <option value="">-- Selecciona un rol --</option>
                        <option v-for="option in availableRoles" :value="option.value" :key="option.value">{{ option.text }}</option>
                    </select>
                </div>
                <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Generar clave</button>
            </form>
            <table v-if="keys.length > 0" class="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr class="bg-gray-200">
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Clave</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Permiso</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="key in keys" :key="key.id_token_clave">
                        <td class="px-6 py-4 whitespace-nowrap"><a :href="`https://sponsor.bmotik.work/registrarse/${key.id_token_clave}`" target="_blank" class="text-blue-500 hover:text-blue-700">{{key.id_token_clave}}</a></td>
                        <td class="px-6 py-4 whitespace-nowrap">{{ key.rol }}</td>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <button @click="deleteKey(key.id_token_clave)" class="text-red-500 hover:text-red-700">Eliminar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p v-else class="text-center">No hay claves generadas.</p>
        </div>
        <div class="mt-6 w-full text-center">
            <button @click="goBack" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Volver</button>
        </div>
        <ModalConfirm :visible="modalVisible" @close="closeModal" :link="link">
            Este link ha sido enviado por correo al usuario.
        </ModalConfirm>
    </div>
</template>



<script>

import axios from 'axios';
import ModalConfirm from "./modal/ModalConfirm.vue"; // Asumiendo que tienes un componente ModalConfirm
import { debounce } from 'lodash';
import { useToast } from "vue-toastification";
export default {
    setup() {      
      const toast = useToast();
      return { toast }
    },
    props: ['groupId'],
    data() {
        return {
            nombre: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: '',
            availableRoles: [],
            keys: [],
            selectedRoleKey: '',
            isNotDashboardRoot: true,
            isAuthorized: false,
            emailExists: false,
            modalVisible: false,
            link: '',
            debouncedCheckEmail: null,
        };
    },
    components: {
        // HomeButton,
        ModalConfirm
    },
    watch: {
        roleToken(newToken) {
            if (newToken) {
            this.validateRole();
            }
        }
    },
    computed: {      
      roleToken() {
        return this.$store.state.roleToken;
      },
      emailClass() {
            return {
                'border-green-500': !this.emailExists && this.validateEmail(this.email),
                'border-yellow-500': this.emailExists
            };
        },
        passwordClass() {
            return {
                'border-red-500': this.password !== this.confirmPassword || this.password.length < 7
            };
        }
    },
    created() {
        this.setAvailableRoles();
        
        this.fetchKeys();
        this.debouncedCheckEmail = debounce(this.checkEmail, 500);
    },
    methods: {
        handleRegister() {
                // Primero verifica si el email es válido.
            if (!this.validateEmail(this.email)) {
                this.toast.warning("Introduce una dirección de correo electrónico válida.");
                return;
            }

            // Verifica si el email ya existe. Si existe, los campos de contraseña no son necesarios y se puede proceder.
            if (!this.emailExists) {
                // Si el email no existe, entonces se deben validar las contraseñas.
                if (this.password.length < 7 || this.confirmPassword.length < 7) {
                    this.toast.error("La contraseña debe tener al menos 7 caracteres.");
                    return;
                }
                if (this.password !== this.confirmPassword) {
                    this.toast.error("Las contraseñas no coinciden.");
                    return;
                }
            }

            let data = {
                nombre: this.emailExists ? "un nombre" : this.nombre,
                email: this.email,
                password: this.emailExists ? "xxxxxxxx" : this.password,
                groupId: this.groupId,
                role: this.role
            };

            
            axios.post('/api/register', data).then(response => {
                this.modalVisible = true;
                this.link = response.data.link;
                this.toast.success(response.data.success);
                this.nombre = '';
                this.email = '';
                this.password = '';
                this.confirmPassword = '';
                this.role = '';
            }).catch(error => {
                console.error('Error creating user:', error);
                this.toast.error("Error al crear el usuario.");
            });
        },
        validateEmail(email) {
            const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        checkEmail() {
            if (this.validateEmail(this.email)) {
                axios.post('/api/check-mail/validate', { email: this.email })
                    .then(response => {
                        // Asumiendo que "valid" es 0 si el email existe, y 1 si no existe
                        // Usaremos 'emailExists' para guardar si el email ya está en uso (true si existe, false si no)
                        this.emailExists = response.data.valid === 0;

                        // Además, podrías querer cambiar el estado de los campos o activar un mensaje
                        if (this.emailExists) {
                            // Código para manejar el caso donde el email ya existe
                            this.emailBorderColor = 'green';
                            this.emailMessage = 'Este usuario ya existe, solo con el correo podrás asignar este usuario a este grupo';
                        } else {
                            // Código para manejar el caso donde el email no existe
                            this.emailBorderColor = 'green';
                            this.emailMessage = '';
                        }
                    })
                    .catch(error => {
                        console.error('Error verificando el email:', error);
                        // Manejar errores, por ejemplo, mostrando un mensaje con un toast
                        this.$toast.error('Error al verificar el email.');
                    });
            } else {
                // En caso de que el email no sea válido según validateEmail
                this.emailBorderColor = 'red';
                this.emailMessage = 'Introduce una dirección de correo electrónico válida.';
            }
        },

        // validateEmail(email) {
        //     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //     return re.test(email);
        // },
        closeModal() {
            this.modalVisible = false;
        },
        handleCreateKey() {
            axios.post('/api/create-key', {
                groupId: this.groupId,
                role: this.selectedRoleKey
            }).then(response => {
                if (response.data.success) {
                    this.toast.success('Clave generada exitosamente');
                    this.keys.push(response.data.key);
                } else {                    
                    this.toast.error('Hubo un problema con la generación de la clave.');
                }
            }).catch(() => {
                this.toast.error('Hubo un problema con la generación de la clave.');
            });
        },
        fetchKeys() {
            axios.get(`/api/keys/${this.groupId}`).then(response => {
                this.keys = response.data.keys;
            }).catch(() => {
                this.toast.error('Error al cargar links de registro.');
                
            });
        },
        deleteKey(token) {
            axios.post('/api/delete-key', { token }).then(response => {
                if (response.data.success) {
                    // Filtra la clave eliminada basándote en su token
                    this.keys = this.keys.filter(key => key.id_token_clave !== token);
                    this.toast.success('Clave eliminada con éxito');
                } else {
                    this.toast.error('Error al eliminar la clave');
                }
            }).catch(error => {
                console.error('Error eliminando clave:', error);
                this.toast.error('Error al eliminar la clave');                
            });
        },

        async setAvailableRoles() {
            
            // console.log("Role token:", this.roleToken);
            console.log("Token del grupo:", this.groupId);

            if (!this.roleToken) {
                this.roleLoaded = true;
                return;
            }
            try {
            const response = await axios.post('/api/verify-role', {
                roleToken: this.roleToken
            });
                // console.log('Role response:', response.data); // Agregar un log para depuración
                this.roleAuth = response.data.role;
                if (this.roleAuth === 'superadministrador') {
                this.availableRoles = [
                    { value: 'superadministrador', text: 'Superadministrador' },
                    { value: 'administrador', text: 'Administrador' },
                    { value: 'usuario', text: 'Usuario' }
                ];
                } else if (this.roleAuth === 'administrador' || this.roleAuth === 'administradorevento') {
                    this.availableRoles = [
                        { value: 'administrador', text: 'Administrador' },
                        { value: 'usuario', text: 'Usuario' }
                    ];
                }
                this.checkAccess(this.roleAuth);
            } catch (error) {
                // console.error('Error verifying role:', error);
                this.roleAuth = null;
            } finally {
                this.roleLoaded = true;
            }
            
            // Este método determina qué roles están disponibles basándose en el usuario que crea el nuevo usuario
            
        },
        checkAccess(role) {            
            if (role === 'usuario') {
                this.toast.error("No tienes permiso para ver esta página.");
                this.goBack();
            }else {                
                this.isAuthorized = true; 
            }
        },
        goBack() {
            this.$router.go(-1); // Navega un paso atrás en el historial del navegador
        },
    }
}
</script>
<style lang="sass" scoped>
.flex-wrap
    display: flex
    flex-direction: row
</style>