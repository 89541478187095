<template>
    <div class="grafica">
      <PieChart :chart-data="chartData" :options="chartOptions" />
    </div>
  </template>
  
  <script>
  import { PieChart } from 'vue-chart-3'; // Importa el componente de PieChart
  
  export default {
    components: {
      PieChart
    },
    props: {
      chartData: Object,  // Asegúrate de recibir chartData como prop
      chartOptions: {
        type: Object,
        default: () => ({
          responsive: true,
          maintainAspectRatio: false, // Importante para que el gráfico se ajuste al contenedor
          type: 'doughnut' // Configura el tipo a 'doughnut' por defecto
        })
      }
    }
  };
  </script>

  <style lang="sass" scoped>
  .grafica
    width: 30% // Ancho relativo al contenedor padre
    min-height: 300px // Altura mínima para asegurar visibilidad
    margin: auto // Centrar el gráfico si es necesario
  </style>
