import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
// import HomeView from '../views/dashboard/HomeView.vue';
import UserLogin from '../views/login/UserLogin.vue';
import SolicitarRecuperacion from '../views/login/SolicitarRecuperacion.vue';
import ExpiredRecovery from '../views/login/ExpiredRecovery.vue';
import CambiarPass from '../views/login/CambiarPass.vue';
import MagicLinkLogin from '../views/login/MagicLinkLogin.vue';
import FormularioRegistrarse  from '../views/login/FormularioRegistrarse.vue';
import NotFound from '../views/NotFound.vue'; // Asegúrate de tener un componente para manejar 404
import EventosView from '../views/eventos/EventosView.vue'; // Asegúrate de tener un componente para manejar 404
import EditEvento from '../views/eventos/EditEvento.vue'; // Asegúrate de tener un componente para manejar 404
import EventCardsUsers from '../views/usuarios/EventCardsUsers.vue'; // Asegúrate de tener un componente para manejar 404
import ListaGruposUsuarios from '../views/usuarios/ListaGruposUsuarios.vue'; // Asegúrate de tener un componente para manejar 404
import AgregarUsuario from '@/components/administracion/AgregarUsuario.vue'; // Asegúrate de importar tu componente
import EditUser from '../views/administracion/EditUser.vue'; // Asegúrate de tener el path correcto
import ApproveUsers from '../views/administracion/ApproveUsers.vue'; // Asegúrate de importar tu componente
import CheckGroups from '../views/administracion/CheckGroups.vue'; // Asegúrate de importar tu componente
  
const routes = [
  {
    path: '/login',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/magic-link-login/:magictoken',
    name: 'MagicLinkLogin',
    component: MagicLinkLogin,
    props: true
  },
  {
    path: '/solicitar-recuperacion',
    name: 'SolicitarRecuperacion',
    component: SolicitarRecuperacion,
  },
  {
    path: '/expired-recovery',
    name: 'ExpiredRecovery',
    component: ExpiredRecovery,
  },
  {
    path: '/cambiar_pass',
    name: 'CambiarPass',
    component: CambiarPass,
  },
  {
    path: '/registrarse/:clave?',
    name: 'FormularioRegistrarse',
    component: FormularioRegistrarse,
    props: route => ({ claveProp: route.params.clave })
  },
  {
    path: '/',
    name: 'UserDashboard',
    component: EventCardsUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/user/edit/:token', // Asegúrate de que el token es el parámetro correcto que necesitas
    name: 'EditUser',
    component: EditUser,
    props: true // Esto permite que el token se pase como una prop al componente
  },
  {
    path: '/lista-usuarios',
    name: 'EventCardsUsers',
    component: EventCardsUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/lista-usuarios/:eventoToken', // Utiliza `:token` como parámetro dinámico
    name: 'ListaGruposUsuarios',
    component: ListaGruposUsuarios,
    meta: {
      requiresAuth: true,
    },
    props: true // Habilita la recepción del parámetro `token` como prop en el componente
  },
  {
    path: '/eventos',
    name: 'EventosView',
    component: EventosView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/eventos/:token', // Utiliza `:token` como parámetro dinámico
    name: 'EditEvento',
    component: EditEvento,
    meta: {
      requiresAuth: true,
    },
    props: true // Habilita la recepción del parámetro `token` como prop en el componente
  },
  {
    path: '/dashboard/usuarios/agregar-usuario/:groupId',
    name: 'AgregarUsuario',
    component: AgregarUsuario,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/approve-users/:eventoToken',
    name: 'ApproveUsers',
    component: ApproveUsers,    
    props: true // 
  },
  
  {
    path: '/check-status-event/:eventoToken',
    name: 'CheckGroups',
    component: CheckGroups,    
    props: true // 
  },
  
  // Agrega más rutas aquí según sea necesario...
  {
    path: '/:pathMatch(.*)*', // Esta es una ruta comodín para manejar todas las rutas no definidas
    name: 'NotFound',
    component: NotFound,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.state.isLoggedIn;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Requiere autenticación y no está logueado
    if (!isLoggedIn) {
      next('/login');
    } else {
      next();
    }
  } else if ((to.name === 'UserLogin' || to.name === 'FormularioRegistrarse') && isLoggedIn) {
    // Está intentando ir a Login o Registrarse estando logueado
    next('/');
  } else {
    // Para cualquier otra ruta que no sea explícitamente pública
    next();
  }
});

export default router;

// {
//   path: '/dashboard/user/edit/:token', // Asegúrate de que el token es el parámetro correcto que necesitas
//   name: 'EditUser',
//   component: EditUser,
//   props: true // Esto permite que el token se pase como una prop al componente
// },
// {
//   path: '/user-details/:userId',
//   name: 'UserDetails',
//   component: () => import('../components/metrics/UserMetrics/DetaillUser.vue'),
//   props: true,
//   meta: {
//     requiresAuth: true,
//   },
// },
// {
//   path: '/dashboard/usuarios/agregar-usuario/:groupId',
//   name: 'AgregarUsuario',
//   component: AgregarUsuario,
//   props: true,
//   meta: {
//     requiresAuth: true,
//   },
// },
// {
//   path: '/registrarse/:clave?',
//   name: 'Registrarse',
//   component: Registrarse,
//   props: route => ({ claveProp: route.params.clave }) // Aquí pasas el parámetro como prop al componente
// },
// {
//   path: '/403',
//   name: 'Forbidden',
//   component: Forbidden
// },
// {
//   path: '/dashboard/usuarios',
//   name: 'DashboardUsuarios',
//   component: DashboardUsuarios,
//   meta: { requiresAuth: true }  // Sólo asegura que el usuario debe estar autenticado para acceder
// }