<template>
    <div class="p-3 border-b border-gray-200 last:border-b-0">
      <div class="grid grid-cols-2 gap-4">
        <div>
          <p><strong>Nombres:</strong> {{ user.nombres }}</p>
          <p><strong>Apellidos:</strong> {{ user.apellidos }}</p>
          <p><strong>Documento:</strong> {{ user.numero_de_documento }} </p>
          <p><strong>Tipo de documento:</strong> {{ user.tipo_de_documento }}</p>
          <p><strong>Correo electrónico:</strong> {{ user.correo_electronico }}</p>
        </div>
        <div>
          <p><strong>Teléfono:</strong> {{ user.telefono }}</p>
          <p><strong>Ciudad:</strong> {{ user.ciudad }}</p>
          <p><strong>País:</strong> {{ user.pais }}</p>
          <p><strong>Departamento:</strong> {{ user.departamento }}</p>
          <p><strong>Categoría:</strong> {{ user.categoria }}</p>
        </div>
      </div>
      <div v-if="isComplete" class="mt-4">
        <p class="text-green-900">
          completo
        </p>
      </div>      
      <p v-else class="mt-4 text-red-500">
        Usuario Incompleto
      </p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    computed: {
      isComplete() {
        const fields = ['nombres', 'apellidos', 'numero_de_documento', 'correo_electronico', 'telefono', 'ciudad', 'pais', 'departamento', 'categoria'];
        return fields.every(field => this.user[field] && this.user[field].trim() !== '');
      }
    },
  }
  </script>
  