<template>
    <div>
      <!-- Botón para disparar la generación de enlaces mágicos -->
      <button @click="generateMagicLinks" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-5 rounded">
        Enviar Magic Links
      </button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useToast } from 'vue-toastification';
  
  export default {
    props: {
      grupoToken: {
        type: String,
        required: true
      }
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    methods: {
      generateMagicLinks() {
        this.toast.info('Procesando solicitud...');
        axios.post('/api/generate-magic-link/group', { groupToken: this.grupoToken })
          .then(response => {            
            this.handleResponse(response.data);
            this.toast.success('Enlaces mágicos generados y enviados correctamente.');
          })
          .catch(error => {
            console.error('Error al generar magic links para el grupo:', error);
            this.toast.error('Error al procesar la solicitud.');
          });
      },
      handleResponse(data) {
        const content = data.links.map(link => `(enviado: ${link.mail}) ${link.correo} \nlink: ${link.magic_link}`).join('\n\n');
        this.downloadTextFile(content, 'magiclinks_grupo.txt');
      },
      downloadTextFile(text, filename) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    }
  }
  </script>
  
  <style scoped>
  button {
    transition: background-color 0.3s ease;
    font-size: 0.7em;
  }
  </style>
  